@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    transition: background-color 0.5s ease;
}

@media (min-width: 1281px) {
    .xl-text-99xl {
        font-size: 25rem !important;
    }
}

@keyframes zz {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

.jumping {
    animation: zz 1s infinite;
}

@layer base {
  .font-outline-white {
    --outline-width: 3px;
    --outline-color: white;
    -webkit-text-stroke: var(--outline-width) var(--outline-color);
  }

    .font-outline-black {
    --outline-width: 6px;
    --outline-color: black;
    -webkit-text-stroke: var(--outline-width) var(--outline-color);
  }
}